type SEO = {
  og_title: string;
  title: string;
  description: string;
  og_description: string;
  og_image: string;
  twitter_title: string;
  twitter_description: string;
  twitter_image: string;
};

type Story = {
  path?: string;
  full_slug?: string;
  name?: string;
};

export default function (seo: SEO, story: Story) {
  const metaTags: { name?: string; content: any; property?: string }[] = [];
  const config = useRuntimeConfig().public;

  const tags = Object.assign({}, seo, {
    title: seo.title || seo.og_title || story.name || "",
    description: seo.description || seo.og_description || "",
    "og:title": seo.og_title || seo.title || story.name || "",
    "og:description": seo.og_description || seo.description || "",
    "og:image": seo.og_image || `${config.SITE_URL}/kudosity-social.jpg`,
    "twitter:title": seo.twitter_title || seo.og_title || seo.title || "",
    "twitter:description": seo.twitter_description || seo.og_description || seo.description || "",
    "twitter:image": seo.twitter_image || seo.og_image || `${config.SITE_URL}/kudosity-social.jpg`,
  });

  Object.entries(tags).forEach(([key, value]) => {
    if (value) {
      if (["_uid", "plugin"].includes(key)) {
        return;
      } else if (["title", "description"].includes(key)) {
        metaTags.push({ name: key, content: value });
      } else {
        metaTags.push({ property: key, content: value });
      }
    }
  });

  const link: { rel: string; href: string }[] = [];

  if (story) {
    let slug = "";
    if (story?.path || story?.full_slug) {
      slug = story?.path ? story?.path : `/${story?.full_slug}`;
      link.push({
        rel: "canonical",
        href: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
      });
    }

    useSeoMeta({
      title: seo.title || seo.og_title || story.name || "",
      description: seo.description || seo.og_description || "",
      ogTitle: seo.og_title || seo.title || story.name || "",
      ogDescription: seo.og_description || seo.description || "",
      ogImage: seo.og_image || `${config.SITE_URL}/kudosity-social.jpg`,
      twitterTitle: seo.twitter_title || seo.og_title || seo.title || "",
      twitterDescription: seo.twitter_description || seo.og_description || seo.description || "",
      twitterImage: seo.twitter_image || seo.og_image || `${config.SITE_URL}/kudosity-social.jpg`,
      twitterCard: "summary_large_image",
      articleModifiedTime: story?.published_at,
    });

    if (["article", "guide"].includes(story?.content?.component)) {
      useSchemaOrg([
        defineArticle({
          "@type": "Article",
          headline: tags?.title,
          description: tags?.description,
          image: story?.content?.image?.filename || `${config.SITE_URL}/kudosity-social.jpg`,
          datePublished: new Date(story?.first_published_at).toISOString(),
          dateModified: new Date(story?.published_at).toISOString(),
        }),
      ]);
    }
    if (["event"].includes(story?.content?.component)) {

      const eventDate = new Date(story?.content?.date || story?.published_at).toISOString();
      const event = {
        name: tags?.title,
        location: [
          {
            "@type": "VirtualLocation",
            url: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
          },
        ],
        image: [tags["og:image"]],
        organizer: {
          name: "Kudosity",
          url: "https://kudosity.com",
        },
        eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
        eventStatus: "https://schema.org/EventScheduled",
        description: tags?.description,
        offers: {
          "@type": "Offer",
          url: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
          price: "0",
          priceCurrency: "AUD",
          availability: "https://schema.org/InStock",
          validFrom: eventDate,
        },
        startDate: eventDate,
        endDate: eventDate,
        recordedIn: {
          "@type": "VideoObject",
          name: tags?.title,
          thumbnailUrl: tags["og:image"],
          contentUrl: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
          description: tags?.description,
          uploadDate: eventDate,
        },
      };

      if(story?.content?.speakers?.length > 0){
        event.performer = story?.content?.speakers?.map((s: Object) => {
          return {
            "@type": "Person",
            "name": s.name,
            "jobTitle": s.citation,
            "worksFor": {
              "@type": "Organization",
              "name": s.company
            }
          }
        });
      }

      useSchemaOrg([
        defineEvent(event),
      ]);
    }
    if (["customer-story", "whitepaper"].includes(story?.content?.component)) {
      useSchemaOrg([
        defineArticle({
          "@type": "Report",
          headline: tags?.title,
          description: tags?.description,
          image: story?.content?.image?.filename || `${config.SITE_URL}/kudosity-social.jpg`,
          datePublished: new Date(story?.first_published_at).toISOString(),
          dateModified: new Date(story?.published_at).toISOString(),
        }),
      ]);
    }

    // Generate BreadCrumbs
    const paths = `@/${story?.full_slug}`?.replace(/\/+$/, "")?.split("/");
    const crumbs = paths.map((path, i) => {
      let name = i > 0 ? textTitleCase(path) : "Kudosity";

      if (i + 1 === paths?.length) return { name: tags.title };

      const item = (path.length > 0 ? paths?.slice(0, i + 1).join("/") : "/").replace("@", config.SITE_URL);

      return { name, item };
    });

    if (crumbs.length > 1) {
      crumbs.splice(0, 1);
      useSchemaOrg([
        defineBreadcrumb({
          itemListElement: crumbs,
        }),
      ]);
    }
  }

  if (metaTags.length > 0) {
    useHead(() => {
      return { title: tags.title, meta: metaTags, link };
    });
  }
}
